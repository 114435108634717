import { Pie, mixins } from 'vue-chartjs';
import { mapState } from 'vuex';

const { reactiveProp } = mixins;

export default {
  extends: Pie,

  mixins: [reactiveProp],

  props: ['options'],

  computed: {
    ...mapState('settings', ['darkTheme']),
  },

  mounted() {
    const customOptions = {
      legend: {
        labels: {
          fontColor: this.darkTheme ? 'white' : 'black',
        },
        position: 'bottom',
      },
    };
    this.renderChart(this.chartData, customOptions);
  },
};
