<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :footer-props="{
      'items-per-page-options': [pagination.per_page],
    }"
    :headers="tableMixin_displayedHeaders"
    :items="items"
    :mobile-breakpoint="0"
    :page="pagination.current_page"
    :server-items-length="pagination.total"
    :class="{ 'content-loading': loading }"
    :loading="loading"
    :item-class="() => 'clickable'"
    disable-sort
    @click:row="tableMixin_onRowClick"
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.seen="{ item }">
      <SeenDocumentMarker :item="item" @toggled="$emit('toggle:seen', item)" />
    </template>

    <template v-slot:item.no="{ index }">
      {{ index + 1 + (pagination.current_page - 1) * pagination.per_page }}.
    </template>

    <template v-slot:item.file_name="{ item }">
      <div class="text-break">{{ item.file_name }}</div>
    </template>

    <template v-slot:item.document_date="{ item }">
      <div :class="{ 'error--text': !item.model.is_paid }">{{ item.document_date }}</div>
    </template>

    <template v-slot:item.supplier="{ item }">
      {{ item.model.supplier.name || item.model.supplier.full_name }}
    </template>

    <template v-slot:item.sub_total="{ item }">
      {{ item.model.sub_total | currency }}
    </template>

    <template v-slot:item.vat="{ item }">
      {{ item.model.vat | percentage }}
    </template>

    <template v-slot:item.description="{ item }">
      <BaseTruncatedField :text="item.model.description" />
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :loading="$store.getters.loading[`delete:api/documents/${item.id}`]"
        :item="item"
      />
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.seen>
          <div class="text-subtitle-2">{{ $t('documents.general.seen') }}</div>
          <SeenDocumentMarker :item="item" @toggled="$emit('toggle:seen', item)" />
        </template>

        <template v-slot:item.document_date>
          <div :class="{ 'error--text': !item.model.is_paid }">{{ item.document_date }}</div>
        </template>

        <template v-slot:item.supplier>
          {{ item.model.supplier.name || item.model.supplier.full_name }}
        </template>

        <template v-slot:item.sub_total>
          {{ item.model.sub_total | currency }}
        </template>

        <template v-slot:item.vat>
          {{ item.model.vat | percentage }}
        </template>

        <template v-slot:item.description>
          {{ item.model.description }}
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';
import SeenDocumentMarker from '@/components/SeenDocumentMarker';
import BaseTruncatedField from '@/components/base/BaseTruncatedField';

export default {
  name: 'ReceivedInvoiceTable',

  components: {
    BaseTruncatedField,
    SeenDocumentMarker,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: '',
          value: 'seen',
          hidden: 'smAndDown',
        },
        {
          text: this.$t('general.no'),
          value: 'no',
          hidden: 'smAndDown',
          hideInExpandedRow: true,
        },
        {
          text: this.$t('documents.labels.file'),
          value: 'file_name',
        },
        {
          text: this.$t('documents.labels.document_date'),
          value: 'document_date',
          hidden: 'xsOnly',
        },
        { text: this.$t('general.supplier'), value: 'supplier', hidden: 'xsOnly' },
        { text: this.$t('general.description'), value: 'description', hidden: 'smAndDown' },
        { text: this.$t('general.sum'), value: 'sub_total', hidden: 'xsOnly' },
        { text: this.$t('general.vat'), value: 'vat', hidden: 'smAndDown' },
        {
          value: 'actions',
        },
      ];
    },
  },

  methods: {
    getRowActions() {
      const actions = [];

      if (this.$vuetify.breakpoint.smAndDown) {
        actions.push({
          callback: (p) => this.$emit('click:row', p),
          label: this.$t('general.controls.preview'),
          icon: 'visibility',
        });
      }

      if (this.$can(['documents.received_invoice.create'])) {
        actions.push({
          callback: (p) => this.$emit('edit', p),
          label: this.$t('general.controls.edit'),
          icon: 'edit',
        });
      }

      if (this.$isAdmin()) {
        actions.push({
          callback: (p) => this.$emit('delete', p),
          label: this.$t('general.controls.delete'),
          icon: 'delete',
        });
      }

      return actions;
    },
  },
};
</script>
