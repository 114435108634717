<template>
  <v-tooltip v-if="!item.seen" open-delay="500" bottom>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon @click.stop="$emit('toggled')">
        <v-icon>star_border</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('documents.general.mark_as_viewed') }}</span>
  </v-tooltip>
  <v-btn v-else icon @click.stop="$emit('toggled')">
    <v-icon color="primary">star</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'SeenDocumentMarker',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
