<template>
  <v-scale-transition origin="center">
    <v-btn
      v-if="showButton"
      color="primary"
      bottom
      dark
      fab
      fixed
      right
      @click.stop="$emit('click')"
    >
      <v-icon color="secondary">
        {{ icon }}
      </v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: 'BasePrimaryActionButton',

  props: {
    icon: {
      type: String,
      default: 'add',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showButton: false,
    };
  },

  mounted() {
    setTimeout(() => {
      this.showButton = true;
    }, 1000);
  },
};
</script>

<style scoped></style>
