<template>
  <div class="px-6 py-10">
    <div class="pt-6 pr-6" :class="$vuetify.breakpoint.xsOnly ? 'pb-4 text-center' : 'text-end'">
      <v-btn-toggle :value="paidFilterValue" rounded>
        <v-tooltip v-for="data in filterButtonsData" :key="data.value" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="data.buttonValue" small @click="onFilterPaid(data)">
              <v-icon>{{ data.icon }}</v-icon>
            </v-btn>
          </template>
          {{ data.tooltip }}
        </v-tooltip>
      </v-btn-toggle>
    </div>

    <div v-if="statistics.length" class="pie-chart-div">
      <PieChart :chart-data="pieChartData" />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax */
import randomColor from 'randomcolor';
import PieChart from '@/charts/PieChart';
import { getSanitizedFilterParams } from '@/util/filter-params';

export default {
  name: 'InvoicesChart',

  components: { PieChart },

  props: {
    statistics: {
      type: Array,
      required: true,
    },
  },

  computed: {
    paidFilterValue() {
      const params = getSanitizedFilterParams(this.$route.query);

      if (params.is_payment_received === true) {
        return 'paid';
      }
      if (params.is_payment_received === false) {
        return 'unpaid';
      }
      return 'all';
    },

    filterButtonsData() {
      return [
        {
          value: null,
          icon: 'list',
          buttonValue: 'all',
          tooltip: this.$t('general.all_invoices'),
        },
        {
          value: true,
          icon: 'check',
          buttonValue: 'paid',
          tooltip: this.$t('general.paid_invoices'),
        },
        {
          value: false,
          icon: 'close',
          buttonValue: 'unpaid',
          tooltip: this.$t('general.unpaid_invoices'),
        },
      ];
    },

    pieChartData() {
      const pieChartData = {
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
        labels: [],
      };

      for (let i = 0; i < this.statistics.length; i++) {
        const client = this.statistics[i];
        pieChartData.datasets[0].data.push(+client.total_sum);
        pieChartData.datasets[0].backgroundColor.push(this.getColorFromId(client.id));
        pieChartData.labels.push(client.name);
      }

      return pieChartData;
    },
  },

  methods: {
    onFilterPaid(data) {
      if (data.buttonValue === this.paidFilterValue) {
        return;
      }
      this.$emit('filter:paid', data.value);
    },

    getColorFromId(id) {
      return randomColor({ seed: id, format: 'rgb', luminosity: 'light' });
    },
  },
};
</script>

<style scoped></style>
