<template>
  <v-container fluid>
    <v-tabs class="elevation-1 table-wrapper">
      <v-tab @click="fetchReceivedInvoices(receivedInvoiceFilterParams)">
        {{ $t('general.received_invoices') }}
      </v-tab>

      <v-tab-item>
        <div class="d-flex align-center justify-end px-4">
          <ReceivedInvoiceFilter
            :filter-params="receivedInvoiceFilterParams"
            @change="$router.push({ name: 'receivedInvoices', query: $event })"
            @reset="resetReceivedInvoiceFilters"
          />

          <v-btn
            :loading="downloadingReceivedInvoices"
            :disabled="downloadingReceivedInvoices"
            icon
            @click="downloadReceivedInvoices"
          >
            <v-icon>file_download</v-icon>
          </v-btn>
        </div>

        <ReceivedInvoiceTable
          :items="receivedInvoices"
          :loading="loadingReceivedInvoices"
          :pagination="receivedInvoicePagination"
          @click:row="openDocument"
          @delete="deleteReceivedInvoice"
          @edit="$router.push({ name: 'editReceivedInvoice', params: { id: $event.id } })"
          @toggle:seen="toggleSeenReceivedInvoice"
          @update:page="onPageChange"
        />

        <InvoicesChart :statistics="receivedInvoiceStatistics" @filter:paid="onFilterPaid" />
      </v-tab-item>
    </v-tabs>

    <BasePrimaryActionButton
      v-if="$can(['documents.received_invoice.create'])"
      icon="file_upload"
      @click="$router.push({ name: 'createReceivedInvoice' })"
    />

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import ReceivedInvoiceTable from '@/components/tables/ReceivedInvoiceTable';
import ReceivedInvoiceFilter from '@/components/filters/ReceivedInvoiceFilter';
import { getDefaultReceivedInvoiceFilterParams } from '@/store/modules/received-invoices-module';
import { getSanitizedFilterParams } from '@/util/filter-params';
import { openFile } from '@/util/event-bus';
import InvoicesChart from '@/components/InvoicesChart';

export default {
  name: 'ReceivedInvoices',

  components: {
    InvoicesChart,
    BasePrimaryActionButton,
    ReceivedInvoiceTable,
    ReceivedInvoiceFilter,
  },

  computed: {
    ...mapState('receivedInvoices', [
      'receivedInvoices',
      'receivedInvoicePagination',
      'receivedInvoiceFilterParams',
      'receivedInvoiceStatistics',
      'downloadingReceivedInvoices',
      'loadingReceivedInvoices',
    ]),
  },

  created() {
    this.fetchReceivedInvoices(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.receivedInvoiceFilterParams) !== JSON.stringify(params) &&
      to.name === 'receivedInvoices'
    ) {
      this.fetchReceivedInvoices(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('receivedInvoices', [
      'fetchReceivedInvoices',
      'deleteReceivedInvoice',
      'downloadReceivedInvoices',
      'toggleSeenReceivedInvoice',
    ]),

    onPageChange(page) {
      const query = { ...this.receivedInvoiceFilterParams, page };
      if (page === 1) {
        delete query.page;
      }
      this.$router.push({ name: 'receivedInvoices', query });
    },

    onFilterPaid(value) {
      const query = { ...this.receivedInvoiceFilterParams, is_payment_received: value };
      if (value === null) {
        delete query.is_payment_received;
      }
      this.$router.push({ name: 'receivedInvoices', query });
    },

    resetReceivedInvoiceFilters() {
      const defaultFilters = getDefaultReceivedInvoiceFilterParams();
      if (JSON.stringify(defaultFilters) === JSON.stringify(this.receivedInvoiceFilterParams)) {
        return;
      }
      this.$router.push({ name: 'receivedInvoices', query: defaultFilters });
    },

    openDocument(attachment) {
      openFile({
        name: attachment.file_name,
        url: `api/document-download/${attachment.id}`,
      });
    },
  },
};
</script>
